export enum Action {
  setPhotoData = 'setPhotoData',
  clearPhotoData = 'clearPhotoData',
  setPermissionsDenied = 'setPermissionsDenied',
  agreeGalleryTerms = 'agreeGalleryTerms',
}

export type ActionPayload =
  | { type: Action.setPhotoData; data: string }
  | { type: Action.clearPhotoData; data: null }
  | { type: Action.setPermissionsDenied; data: boolean }
  | { type: Action.agreeGalleryTerms; data: boolean};

export interface StateAction {
  type: string;
  payload: ActionPayload;
}

export type State = {
  photoData: string | null;
  permissionsDenied: boolean;
  galleryTerms: boolean;
};

export const initialState: State = {
  photoData: null,
  permissionsDenied: false,
  galleryTerms: false
};
