import styled, { css } from 'styled-components';
import {
  colors,
  defaultTransition,
  spacing,
  fontFamily,
} from 'styles/variables';
import { FontTheme } from 'types/fontData';

export interface ButtonProps{
  ActiveStyles: {
    borderRadius?: number;
    fillColor?: string;
    border?: string;
    labelColor?: string;
    fontWeight?: string;
    fontSize?: string;
  },
  fontFamily: string;
}

export interface ImageProps {
  ActiveStyles: {
    borderRadius?: number;
    fillColor?: string;
    border?: string;
  },
  src?: string;
  type?: string;
}

export interface LinkProps{
  accentColor: string;
  fontFamily: string;
  hasGallery?: boolean;
}

const fontSize = 14;
const lineHeight = fontSize*1.35;

const baseText = css`
  color: ${colors.primary};
  font-family: ${fontFamily.buttonText};
  font-size: ${fontSize}px;
  line-height: ${lineHeight}px;
  cursor: pointer;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  &:disabled {
    opacity: 0.3;
  }
  &:hover {
    transform: translate(0, -5px) scale(1.01);
  }
  &:active {
    transform: translate(0, 0) scale(0.999);
  }
  transition: all ${defaultTransition};
`

const baseButton = css`
  ${baseText}
  min-height: 50px;
  margin: 3px;
  padding: ${spacing.base}px ${spacing.small}px;
`;

export const Button = styled.button<ButtonProps>`
  ${baseButton}
  font-family: ${({ theme }: FontTheme) => theme?.defaultBoldFont};
  width: ${({type}) => type === "button" ? `80` : `40`}vw;
  border-style: none;
  background-color: ${({ActiveStyles}) => ActiveStyles?.fillColor || "#FFFFFF"};
  border-radius: ${({ActiveStyles}) => ActiveStyles?.borderRadius || 0 }px;
  border: ${({ActiveStyles}) => ActiveStyles?.border || "none" };
  color: ${({ActiveStyles}) => ActiveStyles?.labelColor || "#FFFFFF" };
  font-size: ${({ActiveStyles}) => ActiveStyles?.fontSize || "14px" };
  ${({ ActiveStyles }) => ActiveStyles?.fontWeight && `font-weight: ${ActiveStyles.fontWeight};`}
  :focus {
    outline:none;
  }
`;

export const Image = styled.img<ImageProps>`
  ${baseButton}
  width: ${({type}) => type === "button" ? `80` : `40`}vw;
  ${({ src }) => src && `background-image: ${src};`}
  border-radius: ${({ActiveStyles}) => ActiveStyles?.borderRadius}px;
  padding-top: 16px;
`

export const Link = styled.a<LinkProps>`
  ${baseText}
  text-transform: none;
  font-family: ${({ theme }: FontTheme) => theme?.defaultFont};
  padding-bottom: 2px;
  margin-bottom: ${spacing.small}px;
  border-bottom: solid ${({accentColor}) => accentColor} 2px;
  ${({hasGallery}) => hasGallery && `
    font-size: ${fontSize/2}px;
    line-height: ${lineHeight/2}px;
    border-bottom: none;
    text-decoration: underline;
    margin-bottom: 0px;
  `};
`;
