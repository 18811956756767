import styled, { css } from 'styled-components';

export const theme = {
  flexboxgrid: {
    gridSize: 12, // columns
    gutterWidth: 0, // rem
    outerMargin: 10, // rem
    mediaQuery: 'only screen',
    container: {
      sm: 46, // rem
      md: 61, // rem
      lg: 75, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 48, // em
      md: 64, // em
      lg: 75, // em
    },
  },
};

export const frameColor = '#FFDD4F';

export const colors = {
  primary: '#ffffff',
  secondary: '#808080',
  tertiary: '#4caf98',
  textColor: '#ffffff',
  linkColor: '#4A6B8A',
  bold: '#ADB0C2',
  light: '#EAEBF0',
  successColor: '#00b126',
  errorColor: '#FF0000',
};

export const backgroundColors = {
  photobooth: '#000000',
  navigation: '#373737',
  webcam: '#000000',
  videoPlayer: '#000000',
  player: '#000000',
  input: '#10181F',
};

export const primaryColor = '#FFDD4F';

const defaultFont = 'Inter';
const defaultFontBold = 'Inter';

export const fontFamily = {
  default: defaultFont,
  photobooth: defaultFont,
  primaryHeader: defaultFontBold,
  secondaryHeader: defaultFont,
  regularText: defaultFont,
  mediumText: defaultFont,
  boldText: defaultFontBold,
  buttonText: defaultFont,
  inputText: defaultFont,
};

const baseline = 8;
const baseFontSize = 18;
const baseLeading = 1.2;

export const spacing = {
  base: baseline,
  small: baseline * 2,
  medium: baseline * 3,
  large: baseline * 5,
  xlarge: baseline * 8,
};

export const fontSizes = {
  h1: baseFontSize * 2.611,
  h2: baseFontSize * 2.05,
  h3: baseFontSize * 1.611,
  h4: baseFontSize * 1.277,
  h5: baseFontSize * 0.777,
  h6: baseFontSize * 0.666,
  h7: baseFontSize * 0.555,
  p: baseFontSize,
};

export const fontLeading = {
  h1: baseLeading,
  h2: baseLeading,
  h3: baseLeading,
  h4: baseLeading,
  h5: baseLeading,
  h6: baseLeading,
  p: baseLeading,
};

export const size = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tabletXXS: 600,
  tabletXS: 768,
  tabletS: 810,
  tablet: 900,
  tabletL: 1024,
  laptopS: 1200,
  laptop: 1330,
  laptopL: 1440,
  desktop: 1441,
  desktopM: 1536,
  desktopSL: 1680,
  desktopL: 2500,
  desktopXL: 4000,
};

export const device = {
  mobileS: `(max-width: ${size.mobileS}px)`,
  mobileM: `(max-width: ${size.mobileM}px)`,
  mobileL: `(max-width: ${size.mobileL}px)`,
  mobileLandScape: `screen and (orientation:landscape) and (min-device-width: 319px) and (max-device-width: 880px)`,
  tabletXXS: `(max-width: ${size.tabletXXS}px)`,
  tabletXS: `(max-width: ${size.tabletXS}px)`,
  tabletS: `(max-width: ${size.tabletS}px)`,
  tablet: `(max-width: ${size.tablet}px)`,
  tabletL: `(max-width: ${size.tabletL}px)`,
  laptopS: `(max-width: ${size.laptopS}px)`,
  laptop: `(max-width: ${size.laptop}px)`,
  laptopL: `(max-width: ${size.laptopL}px)`,
  desktop: `(min-width: ${size.desktop}px)`,
  desktopM: `(min-width: ${size.desktopM}px) and (max-width: ${
    size.desktopSL - 1
  }px)`,
  desktopSL: `(min-width: ${size.desktopSL}px) and (max-width: ${
    size.desktopL - 1
  }px)`,
  desktopL: `(min-width: ${size.desktopL}px)  and (max-width: ${
    size.desktopXL - 1
  }px)`,
  desktopXL: `(min-width: ${size.desktopXL}px)`,
};

export const portrait = {
  ipadPro12: `only screen and (orientation: portrait) and ${device.tabletL}`,
  ipadPro10: `only screen and (orientation: portrait) and ${device.tablet}`,
  ipad9: `only screen and (orientation: portrait) and ${device.tabletS}`,
  iphonePlus: `only screen and (orientation: portrait) and ${device.mobileL}`,
  iphoneX: `only screen and (orientation: portrait) and ${device.mobileM}`,
  iphoneSE: `only screen and (orientation: portrait) and ${device.mobileS}`,
};

export const landscape = {
  ipadPro12: `only screen and (orientation: landscape) and ${device.laptopL}`,
  ipadPro10: `only screen and (orientation: landscape) and ${device.laptop}`,
  ipad9: `only screen and (orientation: landscape) and ${device.tabletL}`,
  ipadMini: `only screen and (orientation: landscape) and ${device.tablet}`,
  iphonePlus: `only screen and (orientation: landscape) and ${device.tabletS} and (max-height: 415px}`,
  iphoneX: `only screen and (orientation: landscape) and ${device.tablet} and (max-height: 400px)`,
  iphoneSE: `only screen and (orientation: landscape) and ${device.tabletXXS} and (max-height: 320px)`,
};

export const defaultTransition = '300ms ease-in-out';

export const screenSizes = {
  maxWidth: 1330,
  maxHeight: 900,
  minWidth: 1024,
  minHeight: 768,
};

export const playerSizes = {
  height: 550,
  width: 950,
};

export const chatSizes = {
  tablet: 202,
  hosted: 332,
  unhosted: 482,
};

export const layout = {
  headerMargin: 20,
  logoWidth: 200,
  headerHeight: 60,
  roomHeight: 825,
  pageMinHeight: 750,
};

export const highResDevice = {
  desktop: `(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), ${device.desktopL}`,
};

const columnStart = css`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

const columnEnd = css`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
`;

const columnCenter = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`;

const spaceBetween = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const spaceStart = css`
  display: flex;
  justify-content: start;
  align-items: start;
`;

const spaceEvenly = css`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const start = css`
  display: flex;
  justify-content: start;
  align-items: center;
`;

const end = css`
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const flexbox = {
  spaceBetween,
  spaceEvenly,
  spaceStart,
  columnCenter,
  columnStart,
  columnEnd,
  start,
  end,
};

const width = css`
  max-width: 95%;
  width: 100%;
  margin: 0 auto;
`;

export const window = {
  width,
};

export const tabletVideoPortrait = css`
  @media only screen and (orientation: portrait) {
    padding: 0 !important;
    width: calc(95vw) !important;
    max-width: inherit !important;
  }
`;

export const GutterCSS = styled.div`
  max-width: 2000px;
  margin: 0 auto;
  @media ${device.tablet} {
    max-width: 95vw;
  }
  @media ${device.tabletL} {
    max-width: 95vw;
  }
`;

export const QRSize = 300;
export const Cloudfront = process.env.REACT_APP_CLOUDFRONT_URL;
