import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import './index.css';
import Main from 'containers/main';
import * as serviceWorker from './serviceWorker';
import { ApolloProvider } from '@apollo/react-hooks';
import { client } from './apollo';

ReactDOM.render(
  <React.StrictMode>
     <ApolloProvider client={client}>
        <Router>
          <Switch>
            <Route path="*">
              <Main />
            </Route>
          </Switch>
        </Router>
     </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
