import {QRCodeSVG} from 'qrcode.react';
import React from 'react';
import { Helmet } from 'react-helmet';
import { usePhotoboothContext } from 'contexts';
import { QRSize, Cloudfront } from 'styles/variables';
import * as Styled from './desktop.styled';

const Desktop = () => {
  const photoboothData = usePhotoboothContext();

  return (
    <Styled.Wrapper>
      <Helmet>
        <html lang={photoboothData?.content?.language || 'en'} />

        <title>{photoboothData?.name}</title>
        <meta
          property='og:title'
          content={photoboothData?.content?.description || 'Photobooth'}
        />
        <meta
          property='og:image'
          content={photoboothData?.landingPage?.content?.topLogo?.s3Path}
        />
        <meta
          property='og:url'
          content={`https://photobooth.lcdigital.io?id=${photoboothData?.id}`}
        />
        <meta
          property='og:description'
          content={`Virtual Photobooth for ${photoboothData?.name}`}
        />
      </Helmet>
      <Styled.Container>
        <Styled.Container>
          <Styled.LogoContainer>
            {photoboothData?.landingPage && (
              <img
                alt='logo'
                src={`${Cloudfront}/${photoboothData?.content?.desktopRedirectLogo?.s3Path}`}
              />
            )}
          </Styled.LogoContainer>
          <Styled.QRContainer>
            <Styled.QRContainerInner>
              <QRCodeSVG size={QRSize} value={window.location.href} />
            </Styled.QRContainerInner>
            <Styled.BodyText>
              SCAN THIS CODE TO OPEN ON YOUR PHONE
            </Styled.BodyText>
          </Styled.QRContainer>
        </Styled.Container>
        {/* <Styled.Frame size={QRSize} alt="frame" src={} /> */}
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default Desktop;
