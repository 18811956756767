import React from 'react';
import styled, { css } from 'styled-components';
import { ButtonText1 } from 'components/typography';
import { colors, backgroundColors, spacing } from 'styles/variables';
import { ReactComponent as Exit } from './imgs/exit-icon.svg';
import { ReactComponent as Rotate } from './imgs/rotate-icon.svg';
import { ReactComponent as Overlay } from './imgs/windowframe.svg';
import Button from 'components/button/button';

interface BottomIconProps {
  parentHeight?: number;
  parentWidth?: number;
  childHeight?: number;
  childWidth?: number;
}

interface CaptureIconProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  hasGallery?: boolean;
  isAmazon?: boolean;
  isAvengers?: boolean;
  isBHID?: boolean;
}
interface CaptureFooterProps {
  background?: string;
  isAmazon?: boolean;
}

interface CaptureContainerProps {
  hasGallery?: boolean;
  direction?: string
}
interface CheckboxProps {
  accentColor?: string
}

interface PreviewProp {
  backgroundImage: string | null;
}

export const baseAssetStyles = css`
  z-index: 5;
  position: absolute;
`;

export const ButtonText = styled(ButtonText1)`
  color: ${colors.primary};
  margin: 0;
  padding: 6px 15px;
  text-transform: uppercase;
`;

export const CameraWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const CaptureContainer = styled.div<CaptureContainerProps>`
  height: 100%;
  width: 100%;
  max-height: 150px;
  display: flex;
  ${({direction}) => direction === 'row' ? `
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  ` : `
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  `}
  ${({hasGallery}) => hasGallery &&  `
    justify-content: space-between;
  `};
`;

export const CaptureFooter = styled.div<CaptureFooterProps>`
  background-image: url(${({background}) => background});
  background-size: cover;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 150px;
  width: 100%;
  padding-top: ${spacing.small}px;
  padding-bottom: ${spacing.small}px;
  ${({isAmazon}) => isAmazon && (
    `
      height: 183px;
    `
  )};
`;

export const CaptureHeader = styled.div<CaptureFooterProps>`
  background-image: url(${({background}) => background});
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  height: calc(70px - ${spacing.small*2}px);
  width: 100%;
  padding-top: ${spacing.small}px;
  padding-bottom: ${spacing.small}px;
`;

/**
 * Capture Icon absolute bottom property is calculated by adding the parent (CaptureFooter) container's bottom margin and half the difference between the parent container's height and the Capture Icon's height  
 */

export const CaptureIcon = styled.img<BottomIconProps>`
  ${baseAssetStyles}
  bottom: ${({parentHeight, childHeight}) => ((((parentHeight || 183) + (spacing.small * 2)) - (84)) / 2)}px;
  left: 50%;
  max-height: 84px;
  transform: translateX(-50%);
`;

export const ExitIcon = styled(Exit)`
  ${baseAssetStyles}
  top: ${spacing.small}px;
  left: ${spacing.small}px;
`;


export const Checkbox = styled.input`
  opacity: 0;
  margin: 6px;
  height: 20px;
  width: 20px;
  position: absolute;
  &:not(:checked) ~ label {
    &:before {
    }
    &:after {
      display: block;
    }
  }
  &:focus + label::before {
    outline: 0;
  }
`;

export const CheckboxLabel = styled.label<CheckboxProps>`
  position: relative;
  min-height: calc(19px + 3vh);
  display: block;
  padding-left: 30px;
  margin-bottom: 0;
  margin-right: 1.5vh;
  font-weight: normal;
  cursor: pointer;
  color: ${({accentColor}) => accentColor || "white"};
  font-size: 11px;
  ~ text {
    text-align: center;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: calc(1.5vh - 6px);
    margin: 6px;
    width: 20px;
    height: 20px;
    transition: transform 0.05s ease;
    border: 1px solid ${({accentColor}) => accentColor || "white"};
    &:focus {
      outline: 0;
    }
  }
  &:after {
    content: '';
    width: 5px;
    height: 10px;
    border: solid ${({accentColor}) => accentColor || "white"};
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: transform ease 0.05s;
    position: absolute;
    display: none;
    top: calc(1.5vh + 3px);
    left: 13px;
  }
`;

export const CheckboxText = styled.p<CheckboxProps>`
  font-size: 11px;
  color: ${({accentColor}) => accentColor || "white"};
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 66vw;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
`
export const ErrorContainer = styled.div`
  display:flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`
export const ErrorText = styled.p`
  color: red;
  font-size: 13px;
  text-align: center;
`

export const OverLayWrapper = styled.div`
  ${baseAssetStyles}
  display: flex;
  align-items: start;
  justify-content: center;
  top: ${spacing.large * 2}px;
  left: ${spacing.small}px;
  width: calc(95vw - ${spacing.small}px);
  height: 60vh;
`

export const OverlayIcon = styled(Overlay)`
  height: 60vh;
`;

export const PhotoPreview = styled.div`
  background-image: url(${({ backgroundImage }: PreviewProp) =>
    backgroundImage});
  background-size: cover;
  background-position: center;
  object-fit: fill;
  height: 100%;
  width: 100%;
`;

export const RetakeIcon = styled(Button)<CaptureIconProps>`
  max-height: 50px;
  ${({isAmazon}) => isAmazon && (`
    color: rgba(255, 255, 255, 0.4);
    border-bottom: solid 2px rgba(255, 255, 255, 0.4);
  `)}
`;

  /**
   * Rotate Icon absolute bottom property ss calculated by adding the parent (CaptureFooter) container's bottom margin and half the difference between the parent container's height and the Rotate Icon's height  
   */
export const RotateIcon = styled(Rotate)<BottomIconProps>`
  ${baseAssetStyles}
  bottom: ${spacing.medium + 39}px;
  right: ${spacing.large}px;
  bottom: ${({parentHeight}) => ((((parentHeight || 183) + (spacing.small * 2)) - 24)/2)}px;
`;

export const SubmitIcon = styled(Button)<CaptureIconProps>`
  max-height: 50px;
  width: 80vw;
  ${({ isBHID }) => isBHID && 'min-width: 165px; color: #000; font-weight: 500;'}
  ${({ isAmazon, hasGallery}) => (isAmazon || !hasGallery) && (`margin-bottom: ${spacing.small}px`)};
`;

export const Wrapper = styled.div`
  touch-action: none;
  background-color: ${backgroundColors.webcam};
  position: absolute;
  height: 100%;
  width: 100%;
`;
