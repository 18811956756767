import styled, { css } from 'styled-components';
import { colors, spacing, fontFamily, fontSizes } from 'styles/variables';
import { Headline1, Paragraph } from 'components/typography';
import { FontTheme } from 'types/fontData';
import Button from 'components/button/button';

interface BodyContainerProps {
  standAlone?: boolean;
}
interface ContainerProps {
  type: string;
  backgroundColor: string;
}

interface ButtonProps {
  ActiveStyles: {
    fillColor?: string;
    borderColor?: string;
    borderRadius?: number;
    labelColor?: string;
    fontSize?: string;
    opacity?: number;
  };
}

interface InputProps {
  ActiveStyles: {
    fillColor?: string;
    border?: string;
    borderRadius?: number;
    labelColor?: string;
    opacity?: number;
    disjointed?: boolean;
  };
}

interface TitleProps extends FontTheme, OverrideProps {
  enlarged?: boolean;
}

interface OverrideProps extends FontTheme {
  isWORE?: boolean;
  isAvengers?: boolean;
  isBHID?: boolean;
  isStartrek?: boolean;
}

interface GalleryConfirmationTextProps {
  galleryFlag?: boolean;
}

const baseFont = css`
  font-size: 18px;
  line-height: 120%;
  color: ${colors.textColor};
  font-family: ${fontFamily.default};
  text-align: center;
`;

export const BackgroundImage = styled.img`
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
`;

export const BodyContainer = styled.div<BodyContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ standAlone }) => standAlone && 'margin: 50% 0 30% 0;'}
`;

export const Container = styled.div<ContainerProps>`
  height: ${({ type }) =>
    type === 'main'
      ? `calc(100% - ${spacing.medium * 2}px)`
      : type === 'confirmed'
      ? '100%'
      : 'auto'};
  display: flex;
  flex-direction: column;
  justify-content: ${({ type }) =>
    type === 'main' ? 'space-between' : 'center'};
  align-items: center;
  padding: ${spacing.medium}px ${spacing.medium}px ${spacing.medium}px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const EndingPageCTA = styled(Button)<OverrideProps>`
  white-space: nowrap;
  font-size: 14px;
  width: 100%;
  max-height: 50px;
  margin-top: 8px;
  &:hover {
    transform: none;
  }
  ${({ isBHID }) =>
    isBHID &&
    `
    color: #000;
    font-weight: 500;  
    margin: 6px 0;
    @media (orientation: portrait) and (max-width: 376px) and (max-height: 678px) {
      margin: 6;
    }
  `};
`;

export const EndingPageCTALink = styled.a`
  margin-top: 8px;
  color: white;
  text-align: center;
  text-decoration: underline;
  &:visited {
    color: white;
  }
`;

export const HeaderLogo = styled.img<OverrideProps>`
  z-index: 0;
  max-width: 100%;
  max-height: 5.5vh;
  margin: 0 0 ${spacing.small}px 0;
  ${({ isAvengers }) => isAvengers && 'max-height: 9.5vh;'};
  ${({ isWORE }) => isWORE && 'max-height: 10.5vh;'};
`;

export const Icon = styled.img`
  position: relative;
  max-height: 46px;
`;

export const Input = styled.input<InputProps>`
  ${baseFont};
  font-family: ${({ theme }: FontTheme) => theme?.defaultFont};
  border: none;
  width: 100%;
  font-size: 18px;
  color: ${({ ActiveStyles }) => ActiveStyles?.labelColor || '#000000'};
  height: calc(${fontSizes.p}px + ${spacing.base * 2}px + 3px - 20px);
  min-height: 32px;
  background: ${({ ActiveStyles }) =>
    ActiveStyles?.fillColor?.includes('rgba')
      ? 'transparent'
      : ActiveStyles?.fillColor};
  ::placeholder {
    color: ${({ ActiveStyles }) => ActiveStyles?.labelColor || '#000000'};
  }
  :focus {
    outline: none;
  }
`;

export const InputContainer = styled.div<InputProps>`
  display: flex;
  align-items: center;
  height: min-content;
  min-height: -moz-min-content;
  min-height: -webkit-min-content;
  width: 100%;
  padding: 8px;
  ${({ ActiveStyles }) =>
    ActiveStyles?.border && `border: ${ActiveStyles?.border}`};
  ${({ ActiveStyles }) =>
    ActiveStyles?.borderRadius &&
    `border-radius: ${ActiveStyles?.borderRadius}px`};
  ${({ ActiveStyles }) =>
    ActiveStyles?.fillColor && `background: ${ActiveStyles?.fillColor}`};
  margin-bottom: ${spacing.small}px;
`;

export const InputError = styled(Paragraph)<FontTheme>`
  text-align: center;
  margin-top: 10px;
  font-size: 0.66rem;
  font-weight: 600;
  font-family: ${({ theme }) => theme?.defaultFont};
  color: ${colors.errorColor};
`;

export const Label = styled.label`
  ${baseFont};
  font-weight: 600;
  display: block;
`;

export const LoadingContainer = styled.div`
  display: flex;
  max-height: 46px;
`;

export const PreviewContainer = styled.img`
  width: 100%;
  max-height: 70vh;
`;

export const PrivacyCopy = styled(Paragraph)<FontTheme>`
  ${baseFont}
  font-size: 9px;
  font-size: calc(min(max(10px, 1vh), 14px));
  line-height: 130%;
  font-family: ${({ theme }) => theme?.defaultFont};
  color: ${colors.primary};
  margin: 1vh 0;
  opacity: 0.8;
`;

export const Routes = styled.div``;

export const SampleHeadshot = styled.img`
  margin-bottom: ${spacing.small}px;
  @media (orientation: portrait) and (max-width: 376px) and (max-height: 678px) {
    margin-bottom: 0;
  }
  max-height: 50vh;
`;
export const SubmitButton = styled(Button)`
  max-height: 50px;
  min-width: calc(100% + 16px);
  :focus {
    outline: none;
  }
`;

export const SubmitContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Text = styled(Paragraph)<OverrideProps>`
  ${baseFont};
  font-family: ${({ theme }) => theme?.defaultFont};
  color: ${colors.textColor};
  font-size: 14px;
  line-height: 120%;
  opacity: 0.8;
  ${({ isBHID }) =>
    isBHID &&
    `font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.02em;
  width: 85%;
  padding-bottom: 50px;
  `}
  margin-bottom: 0.25em;
  & > p {
    margin: 0;
    color: #fff;
    &:not(:first-child) {
      color: rgba(255, 255, 255, 0.4);
    }
  }
`;

export const ProcessingText = styled(Paragraph)<OverrideProps>`
  font-family: ${({ theme }) => theme?.defaultFont};
  ${({ isStartrek }) => isStartrek && 'font-family: Apotek Wide;'}
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 43px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding-bottom: 50px;
`;

export const Title = styled(Headline1)<TitleProps>`
  ${baseFont}
  font-family: ${({ theme }) => theme?.defaultBoldFont};
  font-weight: 500;
  font-size: 17px;
  line-height: 120%;
  color: ${colors.primary};
  text-align: center;
  @media (orientation: portrait) and (max-width: 376px) and (max-height: 678px) {
    margin-bottom: 0;
  }
  margin-bottom: 0.25em;
  ${({ enlarged, isAvengers }) =>
    (enlarged || isAvengers) &&
    `
    font-size: 36px;
    margin-bottom: 0.25em;
  `};
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const LoadingPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 100%;
`;

export const NewLoadingContainer = styled.div`
  width: 100vw;
  position: absolute;
  left: 0;
  height: 100px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Placeholder = styled.div`
  margin: 1vh 0;
`;

export const EndPageCTAWrapper = styled.a`
  width: 100%;
`;

export const GalleryConfirmationText = styled(Paragraph)<
  GalleryConfirmationTextProps
>`
  ${baseFont};
  font-family: ${({ theme }) => theme?.defaultFont};
  color: ${({ galleryFlag }) => (galleryFlag ? 'green' : 'red')};
  margin: 0.4em 0;
  font-size: 10px;
`;
