import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import parse from 'html-react-parser';
import Button from 'components/button';
import { usePhotoboothContext } from 'contexts';
import { Cloudfront } from 'styles/variables';
import Exit from './assets/amazon_exit_X.png';
import getUrlParam from 'utils/getUrlParam';
import { PhotoboothId } from 'containers/main/main';
import * as Styled from './home.styled';

const Home = () => {
  console.log(process.env);
  const { landingPage, content, id, gallery, title } = usePhotoboothContext() || {};

  const [showModal, setShowModal] = useState<Boolean>(false);
  const [hasAgree, setHasAgreed] = useState<Boolean>(false);
  const landingContent = landingPage?.content || {};
  const enforceTerms = content?.enforceTerms || true;
  const standAlone = !landingContent?.topLogo || !landingContent?.thumbnail;
  const userId = getUrlParam('userId');
  const requireUserId = landingContent?.requireUserId || false;
  const allowPhotos = landingContent?.allowPhotos || false;

  const handleClick = () => {
    setHasAgreed(true);
  };

  const handleModalClick = () => {
    setShowModal(!showModal);
  };

  const renderAgreeContainer = (
    <Styled.BodyContainer>
      {landingContent?.titleText && (
        <Styled.Title
          standAlone={standAlone}
          verbose={landingContent?.titleText?.length >= 60}
          uppercase={
            landingContent?.titleUppercase || id === PhotoboothId.Avengers
          }
        >
          {`Welcome to the ${title} photobooth!${gallery ? ' Take your photo now to add it to the gallery.' : ''}`}
        </Styled.Title>
      )}
      {landingContent?.bodyText && (
        <Styled.BodyText standAlone={standAlone}>
          {parse(landingContent?.bodyText || '')}
        </Styled.BodyText>
      )}
      <Styled.TermsContainer>
        <Styled.TermsText standAlone={standAlone}>
          {' '}
          {parse(
            landingContent?.termsText ||
              'By continuing you agree to our <span onClick={()={})}> terms of service </span>',
            {
              replace: (domNode: any) => {
                if (domNode.name === 'span') {
                  delete domNode.attribs.onClick;
                  return (
                    <span
                      {...domNode.attribs}
                      onClick={handleModalClick}
                      style={{ textDecoration: 'underline' }}
                    >
                      terms of service.
                    </span>
                  );
                }
              },
            }
          )}{' '}
        </Styled.TermsText>
      </Styled.TermsContainer>
      <Button
        type='button'
        onClick={handleClick}
        src={landingContent?.buttonImage}
      >
        {' '}
        {landingContent?.CTAText || 'Agree'}{' '}
      </Button>
    </Styled.BodyContainer>
  );

  console.log(`${Cloudfront}/${landingContent?.backgroundImage?.s3Path}`);
  return (
    <Styled.Wrapper>
      {(!enforceTerms || hasAgree) && (
        <Redirect
          to={{
            pathname: '/tip',
            search: `${window.location.search}`,
            state: { hasAgree: true },
          }}
        />
      )}
      {landingContent?.backgroundImage && (
        <Styled.BackgroundImage
          alt='background'
          src={`${Cloudfront}/${landingContent?.backgroundImage?.s3Path}`}
        />
      )}
      {showModal && (
        <Styled.Modal>
          <Styled.ModalExit
            align={id === PhotoboothId.Avengers ? 'left' : 'right'}
            src={Exit}
            onClick={handleModalClick}
          />
          <Styled.Container>
            <Styled.TermsTitle
              align={id === PhotoboothId.Avengers ? 'left' : 'center'}
            >
              {' '}
              {landingContent?.termsTitleText || 'Terms of Use'}{' '}
            </Styled.TermsTitle>
            <Styled.TermsBodyText standAlone={standAlone}>
              {' '}
              {parse(
                landingContent?.termsBodyText ||
                  `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.

            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`
              )}
            </Styled.TermsBodyText>
          </Styled.Container>
        </Styled.Modal>
      )}
      <Styled.Container
        standAlone={standAlone}
        backgroundColor={
          !landingContent?.backgroundImage &&
          (landingContent?.backgroundColor || '#FFFFFF')
        }
      >
        {landingContent?.topLogo && (
          <Styled.HeaderLogo
            alt='headerLogo'
            src={`${Cloudfront}/${landingContent?.topLogo?.s3Path}`}
          />
        )}
        {landingContent?.thumbnail && (
          <Styled.SampleHeadshot
            alt='thumbnail'
            src={`${Cloudfront}/${landingContent?.thumbnail?.s3Path}`}
          />
        )}
        {requireUserId && !userId ? (
          <Styled.Title>
            {' '}
            {landingContent?.requireUserIdText ||
              "This event requires user authentication. Please log in to 'INSERT EVENT URL' with your access code and scan QR code to continue."}{' '}
          </Styled.Title>
        ) : allowPhotos ? (
          renderAgreeContainer
        ) : (
          <Styled.Title>
            {' '}
            {landingContent?.disallowPhotosText || 'No More Photos'}{' '}
          </Styled.Title>
        )}

        {landingContent?.bottomLogo && (
          <Styled.FooterLogo
            alt='footerLogo'
            src={`${Cloudfront}/${landingContent?.bottomLogo?.s3Path}`}
          />
        )}
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default Home;
