import React, { Dispatch, createContext, useReducer } from 'react';
import { Action, StateAction, State, initialState } from "./contextData";

const store = createContext<{
  state: State,
  dispatch: Dispatch<any>
}>({ state: initialState, dispatch: () => null });

const ContextStore = ({ children }: any) => {
  const { Provider } = store;

  const [state, dispatch] = useReducer((state: any, action: StateAction) => {
    switch(action.type) {
      case Action.setPhotoData:
        const newState = {
          ...state,
          photoData: action.payload
        };
        return newState;
      case Action.clearPhotoData:
         return {
           ...state,
           photoData: null
         };
      case Action.setPermissionsDenied:
        return {
          ...state,
          setPermissionsDenied: action.payload
        };
      case Action.agreeGalleryTerms:
        return {
          ...state,
          galleryTerms: action.payload
        };
      default:
        throw new Error("Invalid Action");
    };
  }, initialState);

  return (
    <Provider value={{ state, dispatch }}>
      {children}
    </Provider>
  );
};

export { ContextStore, store };