interface FontFamily {
  defaultFont: string;
  defaultBoldFont: string;
  tertiaryFont?: string;
}

const getFontFamily = (font : string | undefined): FontFamily => {
  switch (font) {
    case 'unpregnant':
      return {
        defaultFont: 'GillSansBold',
        defaultBoldFont: 'GillSansBolder'
      };
    case 'knockout':
      return {
        defaultFont: 'Knockout-HTF29-JuniorLiteweight',
        defaultBoldFont: 'Knockout-HTF70-FullWelterwt'
      };
    case 'blumhouse':
      return {
        defaultFont: 'Apercu-Mono',
        defaultBoldFont: 'DomaineTextTest-Regular'
      };
    case 'discovery':
      return {
        defaultFont: 'Montserrat-ExtraLight',
        defaultBoldFont: 'Montserrat-Light'
      }
    case 'borat':
      return {
        defaultFont: 'Courier',
        defaultBoldFont: 'Courier'
      }
    case 'bigsky':
      return {
        defaultFont: 'Roboto',
        defaultBoldFont: 'akzidenz-grotesk-condensed',
      }
    case 'flightattendant':
      return {
        defaultFont: 'Montserrat-ExtraLight',
        defaultBoldFont: 'Montserrat-Light'
      }
    case 'institches':
      return {
        defaultFont: 'Montserrat-ExtraLight',
        defaultBoldFont: 'Montserrat-Light'
      }
    case 'stand':
      return {
        defaultFont: 'Montserrat-ExtraLight',
        defaultBoldFont: 'Montserrat-Light'
      }
    case 'snoop':
      return {
        defaultFont: 'Montserrat-ExtraLight',
        defaultBoldFont: 'Montserrat-Light'
      }
    case 'amazon':
      return {
        defaultFont: 'Amazon_Ember_Lt',
        defaultBoldFont: 'Amazon_Ember_Rg'
      }
    case 'avengers':
      return {
        defaultFont: 'Montserrat-Light',
        defaultBoldFont: 'Apotek-Wide'
      }
    case 'welcometoearth':
      return {
        defaultFont: 'Roboto',
        defaultBoldFont: 'Aviano-Future-Bold'
      }
    case 'biohazard':
        return {
          defaultFont: 'Inter',
          defaultBoldFont: 'Inter'
        }
    default:
      return {
        defaultFont: 'Roboto',
        defaultBoldFont: 'Roboto',
      };
  }
};

export default getFontFamily;
