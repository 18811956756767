import styled, { css } from 'styled-components';
import { Paragraph } from 'components/typography';
import { backgroundColors, QRSize, colors, fontFamily, spacing } from 'styles/variables';
import { FontTheme } from 'types/fontData';

interface FrameProps{
  size: number;
}

const baseFont = css`
  font-size: 0.722rem;
  color: ${colors.textColor};
  font-family: ${fontFamily.regularText};
`;

export const BodyText = styled(Paragraph)<FontTheme>`
  ${baseFont};
  font-family: ${({ theme }) => theme?.defaultBoldFont};
  font-size: 15px;
  line-height: 135%;
  color: #FFFFFF;
  text-align: center;
  margin-top: ${spacing.large}px;
`;

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Frame = styled.img`
  position: absolute;
  height: ${(props : FrameProps) => props.size * 2}px;
  width: ${(props : FrameProps) => props.size * 2}px;
  top: calc(50vh - ${(props : FrameProps) => (props.size)}px);
  left: calc(50vw - ${(props : FrameProps) => (props.size)}px);
`;

export const LogoContainer = styled.div`
  margin-bottom: ${spacing.large}px;
  height: 10vh;

  img {
    height: 100%;
  }
`;

export const QRContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const QRContainerInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: ${QRSize +15}px;
  height: ${QRSize + 15}px;
`

export const Wrapper = styled.div`
  background: ${backgroundColors.photobooth};
  height: 100vh;
  width: 100%;
`;
