import React from "react";
import * as Styled from "./button.styled";
import { usePhotoboothContext } from "contexts";
import { Props } from "./button.types";

const Button = ({className, children, disabled, onClick, type, src, hasGallery, accentColor }: Props) => {
  const { content } = usePhotoboothContext() || {};
  return (
    type ? ( src ? <Styled.Image
        ActiveStyles={content?.activeButtonStyle} 
        className={className}
        onClick={onClick}
        src={src?.assetURL}
        type={type}/> : 
      <Styled.Button
        ActiveStyles={content?.activeButtonStyle} 
        className={className}
        fontFamily={content?.fonts}
        onClick={onClick}
        type={type}
      >
        {children}
      </Styled.Button>

    ): (
      <Styled.Link
        accentColor={accentColor || content?.accentColor || '#FFFFFF'}
        className={className}
        hasGallery={hasGallery}
        fontFamily={content?.fonts}
        onClick={onClick}
      >
        {children}
      </Styled.Link>
    )
  );
};

export default Button;
