import styled, { css } from 'styled-components';
import { Headline3, ButtonText1, Paragraph } from 'components/typography';
import { colors, spacing, fontFamily } from 'styles/variables';
import { FontTheme } from 'types/fontData';

interface ContainerProps {
  backgroundColor?: string;
}

interface OverrideProps {
  isWORE?: boolean;
  isAvengers?: boolean;
}

const baseFont = css`
  font-size: 15px;
  color: ${colors.textColor};
  font-family: ${fontFamily.default};
`;

export const BackgroundImage = styled.img`
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: ${spacing.medium}px;
`

export const BodyText = styled(Paragraph)`
  ${baseFont};
  font-family: ${({ theme }) => theme?.defaultFont};
  font-size: 14px;
  font-size: 1.5vh;
  line-height: 160%;
  color: ${colors.textColor};
  text-align: center;
  &:first-line {
    font-family: ${({ theme }) => theme?.defaultBoldFont};
  }
`;

export const ButtonText = styled(ButtonText1)`
  color: ${colors.textColor};
  margin: 0;
  padding: 6px 15px;
  text-transform: uppercase;
`;

export const Container = styled.div<ContainerProps>`
  height: calc(100% - ${spacing.medium*2}px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${spacing.medium}px ${spacing.medium}px;
  background-color: ${({backgroundColor}) => backgroundColor};
`;

export const FooterLogo = styled.img`
  z-index: 0;
  max-width: 100%;
  max-height: 75px;
  margin-bottom: ${spacing.large}px;
`

export const HeaderLogo = styled.img<OverrideProps>`
  z-index: 0;
  max-height: 5.5vh;
  margin-bottom: ${spacing.medium}px;
  max-width: 100%;
  ${({isWORE}) => isWORE && ('max-height: 10.5vh;')};
  ${({isAvengers}) => isAvengers && ('max-height: 9.5vh;')};
`
export const Modal = styled.div`
  z-index: 10;
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, .85);
`;

export const ModalExit = styled.span`
  position: absolute;
  top: 25px;
  left: 25px;
  text-align: center;
  height: 15px;
  width: 15px;
  color: #ffffff;
`;

export const SampleHeadshot = styled.img`
  margin-bottom: ${spacing.medium}px;
  max-height: 44vh;
`

export const TermsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TermsText = styled(Paragraph)`
  ${baseFont};
  font-family: ${({ theme }) => theme?.defaultFont};
  font-size: 1.5vh;
  font-size: 13px;
  line-height: 120%;
  color: ${colors.textColor};
  text-align: center;
`;

export const Title = styled(Headline3)<FontTheme>`
  ${baseFont};
  font-family: ${({ theme }) => theme?.defaultBoldFont};
  font-size: 24px;
  font-size: 3vh;
  line-height: 120%;
  font-weight: 900;
  color: ${colors.primary};
  text-align: center;
  margin-bottom: ${spacing.small / 2}px;
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;
