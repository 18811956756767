import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Route, Switch, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { ContextStore } from 'components/contextStore';
import { ThemeProvider } from 'styled-components';
import { PhotoboothContext } from "contexts";
import Capture from 'containers/capture';
import Desktop from 'containers/desktop';
import Home from 'containers/home';
import Submit from 'containers/submit';
import Tip from 'containers/tip';
import usePhotoboothData from 'hooks/usePhotobooth';
import setVh from 'hooks/useVHSetter';
import getUrlParam from 'utils/getUrlParam';
import getFontFamily from 'utils/getFontFamily';
import * as Styled from './main.styled';

export enum PhotoboothId {
  WithoutRemorse = '282dc84f-c89f-4428-aaeb-709c93a00746',
  Avengers = '2e198696-2dd7-4748-be87-dfffb7ea1188',
  Startrek = '437e4cb8-a928-4eca-a92e-515dd1e251e7'
}

const Main = () => {
  setVh();
  const location = useLocation();
  const PhotoboothId = getUrlParam('id');
  const photoboothData = usePhotoboothData(PhotoboothId) || {};
  const fontTheme = getFontFamily(photoboothData?.content?.fonts || "");

  console.log(photoboothData)

  return (
    <ContextStore>
      <PhotoboothContext.Provider value={photoboothData}>
        <ThemeProvider theme={fontTheme}>
          <Styled.Transition>
            <CSSTransition key={location.key} classNames='fade' timeout={500}>
              {isMobileOnly ? (
                <Switch location={location}>
                  <Route exact path='/' component={Home} />
                  <Route exact path='/capture' component={Capture} />
                  <Route exact path='/submit' component={Submit} />
                  <Route exact path='/tip' component={Tip} />
                </Switch>
              ) : (
                <Route path='/' component={Desktop} />
              )}
            </CSSTransition>
          </Styled.Transition>
        </ThemeProvider>
      </PhotoboothContext.Provider>
    </ContextStore>
  );
};

export default Main;
