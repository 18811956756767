import { useQuery } from '@apollo/react-hooks';
import { GET_PHOTOBOOTH_CONTENT } from 'containers/main/queries';

const usePhotoboothData = (id: string) => {
  const { data } = useQuery(GET_PHOTOBOOTH_CONTENT, {
    variables: { id },
  });
  console.log('data.photobooth');
  return data?.photobooth;
};

export default usePhotoboothData;
