import React from 'react';
import * as Styled from './typography.styled';

interface Props {
  children: React.ReactNode,
  textStyle?: React.CSSProperties
};

const Headline1 = ({ children, textStyle, ...restProps }: Props) => (
  <Styled.Head1 style={{ ...textStyle }} {...restProps}>
    {children}
  </Styled.Head1>
);

const Headline2 = ({ children, textStyle, ...restProps }: Props) => (
  <Styled.Head2 style={{ ...textStyle }} {...restProps}>
    {children}
  </Styled.Head2>
);

const Headline3 = ({ children, textStyle, ...restProps }: Props) => (
  <Styled.Head3 style={{ ...textStyle }} {...restProps}>
    {children}
  </Styled.Head3>
);

const Headline4 = ({ children, textStyle, ...restProps }: Props) => (
  <Styled.Head4 style={{ ...textStyle }} {...restProps}>
    {children}
  </Styled.Head4>
);

const Headline5 = ({ children, textStyle, ...restProps }: Props) => (
  <Styled.Head5 style={{ ...textStyle }} {...restProps}>
    {children}
  </Styled.Head5>
);

const Headline6 = ({ children, textStyle, ...restProps }: Props) => (
  <Styled.Head6 style={{ ...textStyle }} {...restProps}>
    {children}
  </Styled.Head6>
);


const Paragraph = ({ children, textStyle, ...restProps }: Props) => (
  <Styled.Paragraph style={{ ...textStyle }} {...restProps}>
    {children}
  </Styled.Paragraph>
);

const Bold = ({ children, restProps }: any) => (
  <Styled.Bold {...restProps}>
    {children}
  </Styled.Bold>
);

const ButtonText1 = ({ children, restProps }: any) => (
  <Styled.Button1 {...restProps}>
    {children}
  </Styled.Button1>
);

export {
  Bold,
  ButtonText1,
  Headline1,
  Headline2,
  Headline3,
  Headline4,
  Headline5,
  Headline6,
  Paragraph
};
