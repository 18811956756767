import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
import * as GPN from 'google-libphonenumber';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Redirect, useHistory } from 'react-router-dom';
import SimpleCrypto from 'simple-crypto-js';
import { Action, store } from 'components/contextStore';
import Loader from 'components/loader';
import { usePhotoboothContext } from 'contexts';
import { colors, Cloudfront } from 'styles/variables';
import formatPhone from 'utils/formatPhone';
import getUrlParam from 'utils/getUrlParam';
// import validateEmail from 'utils/validateEmail';
import { PhotoboothId as PhotoboothEventId } from 'containers/main/main';
import * as Styled from './submit.styled';
import { GET_PHOTOBOOTH_BY_HASH } from 'queries/getAttendeePhotobooth';
import { UPDATE_PHOTOBOOTH_JOB } from 'mutations/updatePhotoboothJob';

const Submit = () => {
  const history = useHistory();
  const {
    state: { photoData, galleryTerms },
    dispatch,
  } = useContext(store);
  const [userInput, setUserInput] = useState<string>('');
  const [serverError, setServerError] = useState<string | null>(null);
  const [textUser] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmed, setConfirmation] = useState<boolean>(false);
  const [onPageInitiallyLoaded, setOnPageInitiallyLoaded] = useState<boolean>(
    false
  );
  const [photoSubmissionTime, setPhotoSubmissionTime] = useState<number>(0);
  const [inClientPhotoUrl, setInClientPhotoUrl] = useState<string>('');
  const [pollingPhoto, setPollingPhoto] = useState<number | undefined>(
    undefined
  );
  const [galleryFlag, setGalleryFlag] = useState<boolean>(galleryTerms || true);
  const [galleryConfirmation, setGalleryConfirmation] = useState<string>('');
  const [hash, setHash] = useState<string>('');
  const { submitPage, endingPage, content, cameraPage, id, gallery } =
    usePhotoboothContext() || {};
  const hasGallery = submitPage?.content?.hasGallery || false;
  const BHIDId = '7c3da548-913b-4047-ad9a-fce1f2bf5680';
  const isBHID = cameraPage?.id === BHIDId;

  const PhotoboothId = getUrlParam('id');
  const userId = getUrlParam('userId');

  // this currently assumes empty array delivery field
  const deliveryMethod = Array.isArray(content?.delivery)
    ? content?.delivery[0] || ''
    : 'SMS';
  const [getUserPhoto] = useLazyQuery(GET_PHOTOBOOTH_BY_HASH, {
    variables: {
      boothID: PhotoboothId,
      activeFlag: true,
      hash,
      order: 'desc',
    },
    onCompleted: (res) => {
      const timeoutPhoto = setTimeout(() => {
        /* TODO - Refactor timeout error */
        // if(!confirmed){
        //   setServerError(submitPage?.content?.timedoutText || 'Sorry, something went wrong.<br /> Please try refreshing the page to try again.')
        // }
      }, 65000);
      if (
        res &&
        Array.isArray(res?.photoboothGallery) &&
        res?.photoboothGallery.length > 0 &&
        res?.photoboothGallery[0].url !== ''
      ) {
        clearTimeout(timeoutPhoto);
        setInClientPhotoUrl(res?.photoboothGallery[0].url);
        setLoading(false);
        setConfirmation(true);
        setOnPageInitiallyLoaded(true);
        clearInterval(pollingPhoto);
      }
    },
    fetchPolicy: 'no-cache',
  });

  const [
    updatePhotoboothJob,
    { error: updatePBError, called: updatePBcalled, loading: updatePBloading },
  ] = useMutation(UPDATE_PHOTOBOOTH_JOB, {
    onError: (err) => {
      console.error(err);
      setServerError(`Error: ${err}`);
    },
  });

  const phoneNumberUtil = GPN.PhoneNumberUtil.getInstance();

  useEffect(() => {
    if (sessionStorage.getItem('saved_phone_number')) {
      setUserInput(
        formatPhone(String(sessionStorage.getItem('saved_phone_number')), '')
      );
    }
    if (photoData && !deliveryMethod) {
      if (galleryFlag) {
        setGalleryConfirmation('Added to Gallery ✓');
      }

      const handleDirectDataSubmission = async (
        deliveryOption: string
      ): Promise<void> => {
        setLoading(true);
        let deliveryOutput;
        try {
          const body = {
            boothID: PhotoboothId,
          };

          console.log('Submitting data to: ', process.env.REACT_APP_CREATE_JOB)
          const postResponse = await axios.post(
            `${process.env.REACT_APP_CREATE_JOB}`,
            body
          );

          const { data }: any = postResponse;
          const createDate = new Date(data?.createdAt);
          const createDateInMs = createDate.getTime();
          const jobHash = data?.filename.split('.')[0];
          setHash(jobHash);

          await setPhotoSubmissionTime(createDateInMs);

          try {
            if (!photoData) throw new Error('No Photo Data to Upload!');

            const putResponse = await axios.put(
              data?.uploadUrl,
              Buffer.from(photoData.split(',')[1], 'base64'),
              {
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Content-Type': 'image/jpeg',
                  'Conent-Encoding': 'base64',
                },
              }
            );
            /**
             *  ! TO-DO: Need to hook up event query to check if photo is back.
             */
            setPollingPhoto(
              setInterval(() => {
                getUserPhoto();
              }, 3000)
            );
            /**
             *  ! TO-DO: Need to hook up event query to check if photo is back.
             */

            const { status } = putResponse;
            if (status === 200 && deliveryOutput) {
              setConfirmation(true);
            }
          } catch (error) {
            if (!deliveryOption) {
              setLoading(true);
              return setServerError(
                `${error.toString()}.<br /> Please try refreshing the page.`
              );
            }
            handleError(error.toString());
          }
        } catch (error) {
          if (!deliveryOption) {
            setLoading(true);
            return setServerError(
              `${error.toString()}.<br /> Please try refreshing the page.`
            );
          }
          handleError(error.toString());
        }
      };
      handleDirectDataSubmission('');
    }
  }, [
    photoData,
    deliveryMethod,
    PhotoboothId,
    userId,
    galleryTerms,
    getUserPhoto,
  ]);

  const handleDataSubmission = async (): Promise<void> => {
    console.log('handleDataSubmission()');
    setLoading(true);
    const SC = new SimpleCrypto("some-secret");
    if (!sessionStorage.getItem('saved_phone_number')) {
      sessionStorage.setItem('saved_phone_number', userInput);
    }
    const formattedPhone = `+1${userInput.replace(/\D/g, '')}`;

    if (textUser && formattedPhone.length < 12)
      return handleError('Phone number is not valid.');

    if (
      !phoneNumberUtil.isValidNumberForRegion(
        phoneNumberUtil.parse(formattedPhone, 'US'),
        'US'
      )
    )
      return handleError('We currently only service US phone numbers.');

    try {
      const postResponse = await axios.post(
        `${process.env.REACT_APP_CREATE_JOB}`,
        {
          boothId: PhotoboothId,
          sms: SC.encrypt(formattedPhone),
          userId: getUrlParam('userId'),
          galleryOptIn: galleryTerms,
        }
      );

      const { data }: any = postResponse;

      try {
        if (!photoData) throw new Error('No Photo Data to Upload!');

        const putResponse = await axios.put(
          data?.uploadUrl,
          Buffer.from(photoData.split(',')[1], 'base64'),
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'image/jpeg',
              'Conent-Encoding': 'base64',
            },
          }
        );
        const { status } = putResponse;

        if (status === 200) setConfirmation(true);
      } catch (error) {
        handleError(error.toString());
      }
    } catch (error) {
      handleError(error.toString());
    }
  };

  const handleInput = (e: any): void => {
    const { value } = e.target;
    return setUserInput(formatPhone(value, userInput));
  };

  const handleError = (error: string) => {
    setServerError(error);
    setLoading(false);
  };

  const handleEndingPageReroute = (): void => {
    dispatch({ type: Action.clearPhotoData });
    dispatch({ type: Action.agreeGalleryTerms, payload: false });
    setConfirmation(false);
    history.push(`/tip${window.location.search}`);
  };

  const handleToggleGallery = async () => {
    await updatePhotoboothJob({
      variables: {
        hash,
        galleryFlag: !galleryFlag,
      },
    });
  };

  // this hook will automatically upload pb job to gallery if enabled
  useEffect(() => {
    if (onPageInitiallyLoaded && galleryFlag) {
      setOnPageInitiallyLoaded(false);
      updatePhotoboothJob({
        variables: {
          hash,
          galleryFlag,
        },
      });
      // adding this logic here to mirror how handleToggleGallery logic and useEffect hook is already set up
      setGalleryFlag(!galleryFlag);
    }
  }, [galleryFlag, hash, onPageInitiallyLoaded, updatePhotoboothJob]);

  useEffect(() => {
    if (updatePBcalled && !updatePBloading && !updatePBError) {
      setGalleryConfirmation(
        galleryFlag ? 'Removed from Gallery ✕' : 'Added to Gallery ✓'
      );
      setGalleryFlag(!galleryFlag);
    }
  }, [updatePBError, updatePBcalled, updatePBloading]);

  const isDisjointed = content?.activeInputStyle?.disjointed || false;

  console.log('rendering submit page');

  // If photoData is null, the user does not have a photo that can be submited,
  // and we should redirect them to the root route. This is most likely to happen
  // if a user enters the submit page onto the url bar, or has refreshed the page.
  return !photoData ? (
    <Redirect to={`/${window.location.search}`} />
  ) : (
    <Styled.Wrapper>
      {submitPage?.content?.backgroundImage && (
        <Styled.BackgroundImage
          alt='background'
          src={`${Cloudfront}/${submitPage?.content?.backgroundImage?.s3Path}`}
        />
      )}
      <Styled.Container
        type='main'
        backgroundColor={
          !submitPage?.content?.backgroundImage &&
          (content?.backgroundColor || '#FFFFFF')
        }
      >
        {!confirmed ? (
          <>
            {submitPage?.content?.topLogo && (
              <Styled.HeaderLogo
                alt='HeaderLogo'
                src={`${Cloudfront}/${submitPage?.content?.topLogo?.s3Path}`}
                isWORE={id === PhotoboothEventId.WithoutRemorse}
                isAvengers={id === PhotoboothEventId.Avengers}
              />
            )}
            {!deliveryMethod && loading ? null : (
              <>
                {submitPage?.content?.thumbnail && (
                  <Styled.SampleHeadshot
                    alt='thumbnail'
                    src={`${Cloudfront}/${submitPage?.content?.thumbnail?.s3Path}`}
                  />
                )}
              </>
            )}
            <Styled.BodyContainer
              standAlone={
                !submitPage?.content?.topLogo && !submitPage?.content?.thumbnail
              }
            >
              {!deliveryMethod && loading ? null : (
                <>
                  {submitPage?.content?.titleText && (
                    <Styled.Title
                      enlarged={
                        !submitPage?.content?.topLogo &&
                        !submitPage?.content?.heroImage
                      }
                      isAvengers={id === PhotoboothEventId.Avengers}
                    >
                      {parse(submitPage?.content?.titleText || 'GREAT SHOT')}
                    </Styled.Title>
                  )}
                  {submitPage?.content?.bodyText && (
                    <Styled.Text>
                      {parse(
                        submitPage?.content?.bodyText ||
                          'WE WILL TEXT YOU YOUR PHOTO SHORTLY'
                      )}
                    </Styled.Text>
                  )}
                </>
              )}
              {deliveryMethod ? (
                <>
                  <Styled.InputContainer
                    ActiveStyles={content?.activeInputStyle}
                  >
                    <Styled.Input
                      autoComplete={textUser ? 'tel' : 'email'}
                      disabled={loading}
                      onChange={handleInput}
                      placeholder={
                        submitPage?.content?.placeholderText || 'PHONE #'
                      }
                      type={textUser ? 'tel' : 'email'} // for mobile keyboard controls
                      ActiveStyles={content?.activeInputStyle}
                      value={userInput || ''}
                    />
                    {!isDisjointed && (
                      <>
                        {loading ? (
                          <Styled.LoadingContainer>
                            <Loader
                              color={colors.secondary}
                              configs={{ height: 46, width: 46 }}
                            />
                          </Styled.LoadingContainer>
                        ) : (
                          <Styled.SubmitContainer
                            onClick={() => handleDataSubmission()}
                          >
                            <Styled.Icon
                              alt='arrow'
                              src={`${Cloudfront}/${submitPage?.content?.sendButtonImage?.s3Path}`}
                            />
                          </Styled.SubmitContainer>
                        )}
                      </>
                    )}
                  </Styled.InputContainer>
                  {isDisjointed && (
                    <>
                      {loading ? (
                        <Styled.LoadingContainer>
                          <Loader
                            color={colors.secondary}
                            configs={{ height: 46, width: 46 }}
                          />
                        </Styled.LoadingContainer>
                      ) : (
                        <Styled.SubmitButton
                          onClick={handleDataSubmission()}
                          type='submit'
                        >
                          {' '}
                          Submit{' '}
                        </Styled.SubmitButton>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {loading ? (
                    <>
                      <Styled.ProcessingText
                        isStartrek={id === PhotoboothEventId.Startrek}
                      >
                        {parse(
                          submitPage?.content?.processingText ||
                            "We're processing your photo. <br/> This may take up to a minute, <br/> please do not reload the page."
                        )}
                      </Styled.ProcessingText>
                      <Styled.NewLoadingContainer>
                        <Loader
                          color={isBHID ? '#FF0000' : colors.secondary}
                          configs={{ height: 64, width: 64 }}
                        />
                      </Styled.NewLoadingContainer>
                    </>
                  ) : (
                    <Styled.SubmitButton
                      onClick={() => handleDataSubmission()}
                      type='submit'
                    >
                      {' '}
                      Submit{' '}
                    </Styled.SubmitButton>
                  )}
                </>
              )}
              {serverError && (
                <Styled.InputError>{parse(serverError)}</Styled.InputError>
              )}
              {submitPage?.content?.bottomLogo && (
                <Styled.HeaderLogo
                  alt='bottomLogo'
                  src={`${Cloudfront}/${submitPage?.content?.bottomLogo?.s3Path}`}
                />
              )}
            </Styled.BodyContainer>
            {deliveryMethod ? (
              <Styled.PrivacyCopy>
                {parse(
                  `${
                    submitPage?.content?.disclaimerText ||
                    'Your phone number is only used for photo delivery and will not be sold or included in any marketing lists.'
                  }`
                )}
              </Styled.PrivacyCopy>
            ) : (
              <Styled.Placeholder></Styled.Placeholder>
            )}
          </>
        ) : (
          <Styled.Container
            type='confirmed'
            backgroundColor={
              !submitPage?.content?.backgroundImage &&
              (content?.backgroundColor || '#FFFFFF')
            }
          >
            {submitPage?.content?.topLogo && (
              <Styled.HeaderLogo
                alt='HeaderLogo'
                src={`${Cloudfront}/${submitPage?.content?.topLogo?.s3Path}`}
                isAvengers={id === PhotoboothEventId.Avengers}
              />
            )}
            {submitPage?.content?.heroImage && (
              <Styled.SampleHeadshot
                alt='heroImage'
                src={
                  deliveryMethod
                    ? `${Cloudfront}/${submitPage?.content?.heroImage?.s3Path}`
                    : `${inClientPhotoUrl}`
                }
              />
            )}
            {endingPage?.content?.bodyText && (
              <Styled.Text>
                {parse(
                  endingPage?.content?.bodyText ||
                    'WE WILL LET YOU KNOW WHEN YOUR PHOTO IS READY. SEE YOU SOON!'
                )}
              </Styled.Text>
            )}
            {serverError && (
              <Styled.InputError>{parse(serverError)}</Styled.InputError>
            )}
            {gallery && !deliveryMethod && hasGallery && (
              <Styled.EndPageCTAWrapper
                style={{ textDecoration: 'none', color: '#ffffff' }}
                onClick={handleToggleGallery}
              >
                <Styled.EndingPageCTA isBHID={isBHID} type='submit'>
                  {galleryConfirmation &&
                  galleryConfirmation !== 'Removed from Gallery ✕'
                    ? submitPage?.content?.galleryRemoveText ||
                      'Remove from Gallery'
                    : submitPage?.content?.galleryText || 'Add to Gallery'}
                </Styled.EndingPageCTA>
              </Styled.EndPageCTAWrapper>
            )}
            {!deliveryMethod && (
              <Styled.EndPageCTAWrapper
                style={{ textDecoration: 'none', color: '#ffffff' }}
                rel='noreferrer'
                target='_blank'
                href={inClientPhotoUrl}
                download
              >
                <Styled.EndingPageCTA isBHID={isBHID} type='submit'>
                  {submitPage?.content?.downloadText || 'Download'}
                </Styled.EndingPageCTA>
              </Styled.EndPageCTAWrapper>
            )}
            {!deliveryMethod ? (
              <Styled.EndingPageCTALink
                rel='noreferrer'
                target='_blank'
                href={`/?id=${PhotoboothId}&userId=${userId}`}
              >
                {submitPage?.content?.retakeText || 'Retake'}
              </Styled.EndingPageCTALink>
            ) : (
              <Styled.EndingPageCTA
                type='submit'
                onClick={handleEndingPageReroute}
                isAvengers={id === PhotoboothEventId.Avengers}
              >
                {' '}
                {endingPage?.content?.ctaText || 'Take Another One!'}{' '}
              </Styled.EndingPageCTA>
            )}
          </Styled.Container>
        )}
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default Submit;
