const formatPhone = (value:string, previousValue:string):string => {
  if (!value) return value;

  const currentValue = value.replace(/\D/g, "");
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7 && currentValue[0] === "1") {
      return `(${currentValue.slice(1, 4)}) ${currentValue.slice(4)}`;
    }
    if (cvLength < 7) {
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    }
    if (cvLength > 10 && currentValue[0] === "1") {
      return `(${currentValue.slice(1, 4)}) ${currentValue.slice(4, 7)}-${currentValue.slice(7, 11)}`
    }
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }
  return "";
};

export default formatPhone;
