import gql from 'graphql-tag';

export const UPDATE_PHOTOBOOTH_JOB = gql`
  mutation UpdatePBJob (
    $hash: String
    $galleryFlag: Boolean
    ) {
    updatePhotoboothJob(
      hash: $hash,
      galleryFlag: $galleryFlag
      ) {
      id
      galleryFlag
    }
  }
`;
