import React from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

interface Configs {
  height: number,
  width: number
}

interface Props {
  color: string,
  configs?: Configs
};

const Page = ({
  configs = {
    height: 10,
    width: 10
  },
  color
}: Props) => (
  <Loader
    type="Oval" // TODO: customize(?)
    height={configs.height}
    width={configs.width}
    color={color}
  />
);

export default Page;
