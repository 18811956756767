import styled, { css } from 'styled-components';
import {
  colors,
  fontFamily,
  fontLeading,
  fontSizes,
  spacing,
} from 'styles/variables';

export const Text = css`
  color: ${colors.textColor};
  font-family: ${fontFamily.primaryHeader};
  display: block;
  margin: 0 0 ${spacing.small}px 0;
  padding: 0;

  a {
    color: ${colors.textColor};
  }
`;

export const Head1 = styled.h1`
  ${Text};
  font-size: ${fontSizes.h1}px;
  font-weight: bold;
  line-height: ${fontLeading.h1};
`;

export const Head2 = styled.h2`
  ${Text}
  font-size: ${fontSizes.h2};
  line-height: ${fontLeading.h2};
  font-weight: bold;
`;

export const Head3 = styled.h3`
  ${Text}
  font-size: ${fontSizes.h3};
  line-height: ${fontLeading.h3};
`;

export const Head4 = styled.h4`
  ${Text}
  font-size: ${fontSizes.h4};
  line-height: ${fontLeading.h4};
`;

export const Head5 = styled.h5`
  ${Text}
  font-size: ${fontSizes.h5};
  line-height: ${fontLeading.h5};
`;

export const Head6 = styled.h6`
  ${Text}
  font-size: ${fontSizes.h5};
  line-height: ${fontLeading.h5};
`;

export const Paragraph = styled.p`
  ${Text}
  font-family: ${fontFamily.default};
  font-size: ${fontSizes.p}px;
  line-height: 1.2;
`;

export const Bold = styled.span`
  font-weight: 700;
`;

export const Button1 = styled.div`
  color: ${colors.textColor};
  margin: 0;
  padding: 6px 15px;
  text-transform: uppercase;
`