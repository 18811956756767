import gql from 'graphql-tag';

export const GET_ATTENDEE_PHOTOBOOTH = gql`
  query PhotoboothGallery(
    $attendeeID: ID!
    $activeFlag: Boolean!
    $boothID: ID!
    $order: String
  ) {
    photoboothGallery(
      boothID: $boothID
      attendeeID: $attendeeID
      activeFlag: $activeFlag
      order: $order
    ) {
      id
      url
      createDate
    }
  }
`;

export const GET_PHOTOBOOTH_BY_HASH = gql`
  query PhotoboothGallery(
    $activeFlag: Boolean!
    $boothID: ID!
    $hash: String!
    $order: String
  ) {
    photoboothGallery(
      boothID: $boothID
      hash: $hash
      activeFlag: $activeFlag
      order: $order
      take: 1
    ) {
      id
      hash
      url
      createDate
    }
  }
`;
