import React from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import Button from 'components/button';
import { usePhotoboothContext } from 'contexts';
import { LocationState } from 'types/locationState';
import { Cloudfront } from 'styles/variables';
import { PhotoboothId } from 'containers/main/main';
import * as Styled from './tip.styled';

const Tip = () => {
  const history = useHistory();
  const { landingPage, tipsPage, content, id } = usePhotoboothContext() || {};
  const tipsContent = tipsPage?.content || {};
  const enforceTerms = content?.enforceTerms || true;
  const allowPhotos = landingPage?.content?.allowPhotos || false;
  const { state } = useLocation<LocationState>();
  const hasAgree = state?.hasAgree || true;

  const handleClick = () => {
    history.push(`/capture${window.location.search}`);
  };

  return (
    <Styled.Wrapper>
      {enforceTerms && !hasAgree && (
        <Redirect to={`/${window.location.search}`} />
      )}
      {tipsContent?.backgroundImage && (
        <Styled.BackgroundImage
          alt='background'
          src={`${Cloudfront}/${tipsContent?.backgroundImage?.s3Path}`}
        />
      )}
      <Styled.Container
        backgroundColor={
          !tipsContent?.backgroundImage &&
          (tipsContent?.backgroundColor || '#FFFFFF')
        }
      >
        {tipsContent?.topLogo && (
          <Styled.HeaderLogo
            alt='headerLogo'
            src={`${Cloudfront}/${tipsContent?.topLogo?.s3Path}`}
            isWORE={id === PhotoboothId.WithoutRemorse}
            isAvengers={id === PhotoboothId.Avengers}
          />
        )}
        {tipsContent?.tipImage && (
          <Styled.SampleHeadshot
            alt='tip'
            src={`${Cloudfront}/${tipsContent?.tipImage?.s3Path}`}
          />
        )}
        {allowPhotos ? (
          <Styled.BodyContainer>
            {tipsContent?.titleText && (
              <Styled.Title>
                {parse(tipsContent?.titleText || 'TIP')}
              </Styled.Title>
            )}
            {tipsContent?.bodyText && (
              <Styled.BodyText>
                {parse(
                  tipsContent?.bodyText ||
                    'To ensure the best outcomes of your photos be at an arm’s length distance away from the camera and making sure you have two good light sources directed at you.'
                )}
              </Styled.BodyText>
            )}
            <Button
              type='button'
              onClick={handleClick}
              src={tipsPage?.tipsContent?.buttonImage}
            >
              {' '}
              {tipsContent?.CTAText || 'Take Photo'}{' '}
            </Button>
          </Styled.BodyContainer>
        ) : (
          <Styled.Title> No More Photos </Styled.Title>
        )}

        {tipsContent?.bottomLogo && (
          <Styled.FooterLogo
            alt='footerLogo'
            src={`${Cloudfront}/${tipsContent?.bottomLogo?.s3Path}`}
          />
        )}
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default Tip;
