import React, { useCallback, useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Webcam from 'react-webcam';
import { Action, store } from 'components/contextStore';
import { usePhotoboothContext } from 'contexts';
import * as Styled from './capture.styled';
import { Cloudfront } from 'styles/variables';
import { PhotoboothId } from 'containers/main/main';

const Capture = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(store);
  const { photoData } = state;
  const { cameraPage, name, content, id } = usePhotoboothContext() || {};
  const cameraContent = cameraPage?.content || {};
  const [environment, setEnvironment] = useState<boolean>(false);
  const [agreeGalleryTerms, setAgreeGalleryTerms] = useState<boolean>(true);
  const [cameraError, setCameraError] = useState<string>('');
  const $camera = useRef<any>();
  const hasGallery = cameraContent?.hasGallery || false;
  const videoConstraints = {
    facingMode: { exact: environment ? 'environment' : 'user' },
  };
  /**
   * Need to change next line to isAmazon
   */
  const amazonId = '590ad17c-1030-4abe-a177-5d74a5d32531';
  const TMOPTPTId = '13ef3cdf-eb93-4973-8a92-2f6404cebfa5';
  const BHIDId = '7c3da548-913b-4047-ad9a-fce1f2bf5680';
  const isBHID = cameraPage?.id === BHIDId;
  const isAmazon = cameraPage?.id === amazonId;
  const hasHeader = isAmazon || cameraPage?.id === TMOPTPTId;
  const footerHeight = isAmazon ? 183 : 150;

  /**
   * Handles photo capturing logic
   */
  const handleCapture = useCallback(() => {
    const node = $camera.current;
    if (node) {
      const screenShot = node.getScreenshot();
      dispatch({ type: Action.setPhotoData, payload: screenShot });
    }
  }, [$camera, dispatch]);

  const handleExit = (): void => {
    dispatch({ type: Action.clearPhotoData });
    history.push(`/${window.location.search}`);
  };

  const handleRetake = () => {
    dispatch({ type: Action.clearPhotoData });
  };

  const handleRotate = (): void => {
    setEnvironment((prevState: boolean) => !prevState);
  };

  const handleSubmit = (): void => {
    if (!photoData) return;
    dispatch({ type: Action.agreeGalleryTerms, payload: agreeGalleryTerms });
    history.push(`/submit${window.location.search}`);
  };

  const handleGalleryAgreement = (): void => {
    setAgreeGalleryTerms(!agreeGalleryTerms);
  };

  /**
   * Evaluates webcam error object for user permissions denial
   * @param {Error Object} error
   */
  const handleMediaError = (error: any) => {
    const formattedError = error.toString();
    if (formattedError.includes('NotAllowedError')) {
      setCameraError(
        cameraContent?.notAllowedErrorText ||
          'Camera could not be initilized, please check browser settings and allow camera access/permissions'
      );
    }
    if (formattedError.includes('Requested device not found')) {
      setCameraError(
        cameraContent?.deviceNotFoundErrorText ||
          'Camera could not be initilized, please check if there is an accessible camera and allow access'
      );
    }
  };

  /**
   *  Re-Usable Components
   */
  const RetakeIcon = (
    <Styled.RetakeIcon
      type={cameraContent?.direction === 'row' ? 'submit' : undefined}
      onClick={handleRetake}
      accentColor='#FFFFFF'
      isAmazon={isAmazon}
    >
      {' '}
      {cameraContent?.retakeText || (isAmazon ? 'Retake Photo' : 'Retake')}{' '}
    </Styled.RetakeIcon>
  );

  const SubmitIcon = (
    <Styled.SubmitIcon
      isBHID={isBHID}
      hasGallery={hasGallery}
      type='submit'
      onClick={handleSubmit}
      src={cameraPage?.content?.submitButtonImage}
      isAmazon={isAmazon}
      isAvengers={id === PhotoboothId.Avengers}
    >
      {' '}
      {cameraContent?.submitText || (isAmazon ? 'SUBMIT' : 'CONTINUE')}{' '}
    </Styled.SubmitIcon>
  );

  return (
    <Styled.Wrapper>
      {!photoData && <Styled.ExitIcon onClick={handleExit} />}
      {!photoData && cameraError && (
        <Styled.ErrorContainer>
          <Styled.ErrorText>{cameraError}</Styled.ErrorText>
        </Styled.ErrorContainer>
      )}
      {photoData && <Styled.PhotoPreview backgroundImage={photoData} />}
      {/* {(!photoData && blumhouse) && (
        <Styled.OverLayWrapper>
          <Styled.OverlayIcon />
        </Styled.OverLayWrapper>
      )} */}
      {!photoData && (
        <Styled.CameraWrapper>
          <Webcam
            audio={false}
            ref={$camera}
            onUserMediaError={handleMediaError}
            videoConstraints={videoConstraints}
            style={{
              height: 'inherit',
              width: 'inherit',
              objectFit: 'cover',
            }}
            screenshotFormat='image/jpeg'
            minScreenshotWidth={1080}
            minScreenshotHeight={1920}
            mirrored={!environment}
          />
        </Styled.CameraWrapper>
      )}
      {hasHeader && (
        <Styled.CaptureHeader
          background={`${Cloudfront}/${cameraContent?.buttonBackground?.s3Path}`}
        />
      )}
      <Styled.CaptureFooter
        background={`${Cloudfront}/${cameraContent?.buttonBackground?.s3Path}`}
        isAmazon={isAmazon}
      >
        {photoData ? (
          <Styled.CaptureContainer hasGallery={hasGallery}>
            {(() => {
              switch (cameraPage?.id) {
                case amazonId:
                  return (
                    <>
                      {hasGallery && (
                        <Styled.CheckboxContainer>
                          <Styled.Checkbox
                            onChange={handleGalleryAgreement}
                            id='gallery'
                            key='gallery'
                            name='gallery'
                            type='checkbox'
                          />
                          <Styled.CheckboxLabel
                            htmlFor='gallery'
                            accentColor={content?.accentColor}
                          />
                          <Styled.CheckboxText
                            accentColor={content?.accentColor}
                          >
                            {' '}
                            {cameraContent?.galleryText ||
                              `Add your photo to the Red Carpet gallery for our ${
                                name || ''
                              } Premiere!`}
                          </Styled.CheckboxText>
                        </Styled.CheckboxContainer>
                      )}
                      {SubmitIcon}
                      {RetakeIcon}
                    </>
                  );
                default:
                  return (
                    <>
                      {hasGallery && cameraContent?.direction === 'row' && (
                        <Styled.CheckboxContainer>
                          <Styled.Checkbox
                            onChange={handleGalleryAgreement}
                            id='gallery'
                            key='gallery'
                            name='gallery'
                            type='checkbox'
                          />
                          <Styled.CheckboxLabel htmlFor='gallery' />
                          <Styled.CheckboxText>
                            {' '}
                            {cameraContent?.galleryText ||
                              `Add your photo to the Red Carpet gallery for our ${
                                name || ''
                              } Premiere!`}
                          </Styled.CheckboxText>
                        </Styled.CheckboxContainer>
                      )}
                      <Styled.CaptureContainer
                        direction={cameraContent?.direction}
                      >
                        {RetakeIcon}
                        {hasGallery && cameraContent?.direction === 'column' && (
                          <Styled.CheckboxContainer>
                            <Styled.Checkbox
                              onChange={handleGalleryAgreement}
                              id='gallery'
                              key='gallery'
                              name='gallery'
                              type='checkbox'
                            />
                            <Styled.CheckboxLabel htmlFor='gallery' />
                            <Styled.CheckboxText>
                              {' '}
                              {cameraContent?.galleryText ||
                                `Add your photo to the Red Carpet gallery for our ${
                                  name || ''
                                } Premiere!`}
                            </Styled.CheckboxText>
                          </Styled.CheckboxContainer>
                        )}
                        {SubmitIcon}
                      </Styled.CaptureContainer>
                    </>
                  );
              }
            })()}
          </Styled.CaptureContainer>
        ) : (
          <>
            <Styled.CaptureIcon
              src={`${Cloudfront}/${cameraPage?.content?.captureButtonImage?.s3Path}`}
              onClick={handleCapture}
              parentHeight={footerHeight}
              childHeight={cameraPage?.content?.captureButtonImage?.height}
            />
            <Styled.RotateIcon
              onClick={handleRotate}
              parentHeight={footerHeight}
            />
          </>
        )}
      </Styled.CaptureFooter>
    </Styled.Wrapper>
  );
};

export default Capture;
