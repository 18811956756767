import styled, { css } from 'styled-components';
import { Headline3, ButtonText1, Paragraph } from 'components/typography';
import { colors, spacing, fontFamily } from 'styles/variables';
import { FontTheme } from 'types/fontData';

interface ContainerProps {
  backgroundColor?: string;
  standAlone?: boolean;
}

interface ModalExitProps {
  align?: string;
}
interface TitleTextProps extends FontTheme, ModalExitProps{
  verbose?: boolean;
  standAlone?: boolean;
  uppercase?: boolean;
}

interface ParagraphProps extends React.AllHTMLAttributes<Text> {
  standAlone?: boolean;
}

interface OverrideProps {
  isWORE?: boolean;
  isAvengers?: boolean;
}

type ParagraphTextProps = ParagraphProps & FontTheme;

const baseFont = css`
  font-size: 15px;
  color: ${colors.textColor};
  font-family: ${fontFamily.default};
`;

export const BackgroundImage = styled.img`
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: ${spacing.medium}px;
`

export const BodyText = styled(Paragraph)<ParagraphProps>`
  ${baseFont};
  overflow: auto;
  font-family: ${({ theme }) => theme?.defaultFont};
  font-size: calc(min(max(12px, 1.5vh), 14px));;
  line-height: 110%;
  color: ${colors.textColor};
  text-align: center;
`;

export const ButtonText = styled(ButtonText1)`
  color: ${colors.textColor};
  margin: 0;
  padding: 6px 15px;
  text-transform: uppercase;
`;

export const Container = styled.div<ContainerProps>`
  height: calc(100% - ${spacing.medium*2}px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${spacing.medium}px ${spacing.medium}px;
  background-color: ${({backgroundColor}) => backgroundColor};
  ${({standAlone}) => standAlone && (`
    padding:  ${spacing.large}px;
  `)};
`;

export const FooterLogo = styled.img`
  z-index: 0;
  max-width: 100%;
  max-height: 75px;
  margin-bottom: ${spacing.large}px;
`

export const HeaderLogo = styled.img<OverrideProps>`
  z-index: 0;
  max-width: 100%;
  max-height: 5.5vh;
  margin-bottom: ${spacing.medium}px;
  ${({isAvengers}) => isAvengers && ('max-height: 9.5vh;')};
  ${({isWORE}) => isWORE && ('max-height: 10.5vh;')};
`
export const Modal = styled.div`
  z-index: 10;
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, .85);
`;

export const ModalExit = styled.img<ModalExitProps>`
  position: absolute;
  top: 25px;
  ${({align}) => align === 'right' ? 'right: 25px;' : 'left: 25px'};
  text-align: center;
  height: 15px;
  width: 15px;
  color: #ffffff;
`;

export const SampleHeadshot = styled.img`
  margin-bottom: ${spacing.medium}px;
  max-height: 41vh;
  max-width: 100%;
`

export const TermsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TermsBodyText = styled(BodyText)<ParagraphTextProps>`
  text-align: left;
  line-height: 150%;
  ${({standAlone, theme}) => standAlone && (`
    color: rgba(255, 255, 255, 1);
    font-family: ${theme?.defaultFont};
    font-size: 16px;
  `)}
`

export const TermsText = styled(Paragraph)<ParagraphTextProps>`
  ${baseFont};
  font-family: ${({ theme }) => theme?.defaultFont};
  font-size: 13px;
  font-size: calc(min(max(11px, 1.5vh), 13px));
  line-height: 120%;
  color: ${colors.textColor};
  text-align: center;
  ${({standAlone }) => standAlone && (`
    color: rgba(255, 255, 255, 0.4);
  `)}
`;

export const Title = styled(Headline3)<TitleTextProps>`
  ${baseFont};
  font-family: ${({ theme }) => theme?.defaultBoldFont};
  font-size: calc(min(max(17px, 3vh), 24px));
  ${({ verbose }) => verbose && 'font-size: calc(min(max(12px, 2vh), 17px))'};
  line-height: 120%;
  font-weight: 900;
  color: ${colors.primary};
  text-align: center;
  margin-bottom: ${spacing.small / 2}px;
  ${({ standAlone, theme }) => standAlone && `
    font-size: calc(min(max(17px, 3vh), 24px));
    font-family: ${theme?.defaultFont}};
    font-weight: 300;
  `};
  ${({ uppercase }) => uppercase && `
    text-transform: uppercase;
  `};
`;

export const TermsTitle = styled(Title)`
${({ align }) => align && `
  text-align: ${align};
`}
  width: 100%;
  font-size: 28px;
  margin: 48px 0 24px 0;
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;
